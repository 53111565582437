<template>
  <b-row>
    <b-col>
      <h1 class="text-capitalize mb-2">
        please choose an entity
      </h1>
    </b-col>

    <b-col cols="12">
      <b-card>
        <b-row>
          <b-col md="9">
            <inpo-select />
          </b-col>
          <b-col
            md="3"
            class="text-center text-md-left mt-0 mt-sm-2"
          >
            <b-button
              :disabled="!$store.getters['mainEntity/getEntityId']"
              variant="primary"
              class="btn-block"
              @click="$router.push({name:'inpo-dashboard'})"
            >
              Organization Dashboard
            </b-button>

          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import InpoSelect from '@/common/components/common/InpoSelect.vue'

export default {
  name: 'Home',
  components: {
    InpoSelect,
  },
}
</script>

<style lang="scss">

</style>
