<template>
  <b-form-group
    v-if="entities.length !==0"
    label="Entity"
  >
    <v-select
      v-model="inpoId"
      :options="entities"
      :reduce="entity => entity.id"
      label="name"
      @option:selected="setInpoIdAndApplyUserPermissionsInSelectedInpo"
    />
  </b-form-group>
  <b-row
    v-else
    align-h="center"
  >
    <b-col cols="1">
      <div
        class="loader"
      />
    </b-col>
  </b-row>

</template>

<script>
import vSelect from 'vue-select'
import getAllInposOptions from '@/common/compositions/common/getInpos'
import getUserPermissionsInInpo from '@/common/compositions/common/getUserPermissionsInInpo'

export default {
  name: 'InpoSelect',
  components: {
    vSelect,
  },
  data() {
    return {
      inpoId: this.$store.getters['mainEntity/getEntityId'],
      entities: [],
    }
  },
  setup() {
    const { checkUserRoleAndGetHisInpos } = getAllInposOptions()
    const { checkIsUserAdminAndGetSelectedInpoPermissionsPromise } = getUserPermissionsInInpo()

    return {
      checkUserRoleAndGetHisInpos,
      checkIsUserAdminAndGetSelectedInpoPermissionsPromise,
    }
  },
  created() {
    this.getEntities()
  },
  methods: {
    getEntities() {
      this.checkUserRoleAndGetHisInpos().then(res => {
        this.entities = res
        this.setEntityToFirstOne()
      })
    },
    setEntityToFirstOne() {
      if (this.isUserHaveOneEntity()) { this.inpoId = this.entities[1].id }
    },
    isUserHaveOneEntity() {
      return this.entities.length === 2
    },
    setInpoIdAndApplyUserPermissionsInSelectedInpo(inpo) {
      this.checkIsUserAdminAndGetSelectedInpoPermissionsPromise(inpo.id)
      this.changeMainEntityInStore(inpo)
      this.saveMainEntityToLocalStorage(inpo)
    },
    changeMainEntityInStore(inpo) {
      this.$store.commit('mainEntity/setEntityId', inpo.id)
      this.$store.commit('mainEntity/setEntityName', inpo.name)
    },
    saveMainEntityToLocalStorage(inpo) {
      localStorage.setItem('entityId', inpo.id)
      localStorage.setItem('entityName', inpo.name)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
