import axiosInstances from '@/libs/axios-instances'
import checkUserIsAdmin from '@/common/compositions/common/checkUserIsAdmin'
import store from '@/store'

export default function getInpos() {
  const { isUserAdminPromise } = checkUserIsAdmin()

  const getSuperAdminInposOptions = () => axiosInstances.activities.post('internalops/live/getEntitesDDL')
    .then(res => res.data)

  const getAdminInposNameByIDs = inposID => axiosInstances.http
    .post(`${process.env.VUE_APP_ENTITIES_BASE_URL}/internalops/entities/get_entities_details`, { entity_id: inposID })
    .then(res => res.data.data.flat())

  const getAdminInposID = () => {
    const { username } = store.getters['auth/getUserData']
    return axiosInstances.portalUsers.get(`v1/user/entities/${username}`).then(res => {
      const allEntities = res.data.data

      return getAdminInposNameByIDs(allEntities)
    })
  }

  const checkUserRoleAndGetHisInpos = () => isUserAdminPromise()
    .then(() => getSuperAdminInposOptions())
    .catch(() => getAdminInposID())

  return {
    checkUserRoleAndGetHisInpos,
  }
}
